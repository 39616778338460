import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledSection,
} from './styles'

const Section = ({
  theme, ...props
}) => (
  <StyledSection theme={theme} {...props} />
)

Section.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'short',
    'full',
    'text-box',
  ]),
}

Section.defaultProps = {
  theme: 'default',
}

export default Section
