import styled from 'styled-components'
import COLORS from '../../../constants/colors'

/* eslint-disable import/prefer-default-export */
export const StyledSection = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 8rem;
  margin-bottom: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 1040px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  ${props => (props.theme === 'short'
      && `
      max-width: 75rem;
  `)
    || (props.theme === 'full'
      && `
      max-width: none;
  `)
    || (props.theme === 'text-box'
      && `
      border: 4px solid ${COLORS.GREYLIGHT};
      margin: 4rem auto;
      max-width: 88rem;
      background: #ffffff;
      padding: 4rem;
  `)
    || null}
`
