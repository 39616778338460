import React from 'react'
import PropTypes from 'prop-types'

import * as themes from './styles'

const Title = ({
  level, ...props
}) => {
  const theme = Number.isFinite(level) ? `h${level}` : level
  const Component = themes[theme]

  return <Component {...props} />
}

Title.propTypes = {
  level: PropTypes.oneOfType([
    PropTypes.number,
  ]),
}

Title.defaultProps = {
  level: 1,
}

export default Title
