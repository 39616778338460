import styled from 'styled-components'
import colors from '../../../../constants/colors'

/* eslint-disable import/prefer-default-export */
const SectionWrap = styled.div`
  width: 100%;
  overflow: auto;
  ${props => (props.theme === 'grey'
      && `
    background-color: ${colors.GREYLIGHT};
  `)
    || (props.theme === 'blue'
      && `
      background-color: ${colors.BLUE};
  `)
    || null}
`

export default SectionWrap
