import styled from 'styled-components'

import COLORS from '../../../constants/colors'
import FONTS from '../../../constants/fonts'

export const h1 = styled.h1`
  font-size: ${FONTS.TITLEONE};
  font-weight: ${FONTS.WEIGHTREGULAR};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: ${props => (props.margined ? '2.5rem' : '0')};
`

export const h2 = styled.h2`
  color: ${props => (props.black ? `${COLORS.BLACK}` : `${COLORS.BLUE}`)};
  font-size: ${FONTS.TITLETWO};
  font-weight: ${FONTS.WEIGHTREGULAR};
  font-weight: ${FONTS.WEIGHTMEDIUM};
  margin-top: 0;
  margin-bottom: ${props => (props.margined ? '1rem' : '0')};
`

export const h3 = styled.h3`
  color: ${COLORS.BLACK};
  font-size: ${FONTS.TITLETHREE};
  font-weight: ${FONTS.WEIGHTREGULAR};
  margin-top: 0;
  margin-bottom: ${props => (props.margined ? '2.5rem' : '0')};
`
