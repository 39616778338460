import styled from 'styled-components'

import fonts from '../../../constants/fonts'

/* eslint-disable import/prefer-default-export */
export const StyledP = styled.p`
  margin: 2rem 0;
  font-size: ${fonts.TEXTDEFAULT};
  ${props => (props.theme === 'center'
      && `
    text-align: center;
  `)
    || (props.theme === 'short'
      && `
    max-width: 64rem;
    margin: 2rem auto;
  `)
    || null}
`
