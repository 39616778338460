import styled from 'styled-components'
import fonts from '../../../constants/fonts'

/* eslint-disable import/prefer-default-export */
export const StyledText = styled.span`
  font-size: ${fonts.TEXTDEFAULT};
  ${props => (props.theme === 'small'
      && `
    font-size:  ${fonts.TEXTSMALL};
  `)
    || (props.theme === 'medium'
      && `
      font-size: ${fonts.TEXTMEDIUM};
  `)
    || (props.theme === 'large'
      && `
      font-size: ${fonts.TEXTLARGE};
  `)
    || (props.theme === 'intro'
      && `
      font-size: ${fonts.TEXTINTRO};
  `)
    || (props.theme === 'columns'
      && `
      font-size: ${fonts.TEXTCOLUMNS};
  `)
    || null}
`
