import React, {
  useEffect,
} from 'react'
import {
  graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Header from '../../components/Header'
import PageHero from '../../components/Blocks/PageHero'
import Footer from '../../components/Footer'
import {
  Page, Main,
} from '../../components/StyledComponents'
import Title from '../../components/Base/Title'
import Text from '../../components/Base/Text'
import Paragraph from '../../components/Base/Paragraph'
import Section from '../../components/Base/Section'
import SectionWrap from '../../components/Base/Section/Wrap'
import IconCustom from '../../components/Base/Icon/Custom'
import FormsContact from '../../components/Blocks/Forms/Contact'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-contact-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-contact.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (hash.length > 0) {
      document.getElementById(hash).scrollIntoView()
    }
  })

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='Contact Us'
        description='Please reach out and a team member will be in touch to help you soon.'
      />
      <Page>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='Contact Us'
          />
          <SectionWrap theme='grey' id='contact'>
            <Section center theme='short'>
              <IconCustom theme='hello' margined />
              <Title level={2} margined>
                Contact Us
              </Title>
              <Title level={3}>
                Please reach out and a team member will be in touch to help you
                soon. Thanks!
              </Title>
            </Section>
            <Section>
              <FormsContact />
            </Section>
          </SectionWrap>
          <Section center>
            <Paragraph>
              <Text theme='intro'><strong>Corporate Mailing Address</strong></Text>
            </Paragraph>

            <Paragraph>
              <Text theme='intro'>
                ChartMedic, LLC
                <br />
                414 NE Ravenna Blvd
                <br />
                Suite A #1047
                <br />
                Seattle, WA 98115
              </Text>
            </Paragraph>

            <Paragraph>
              <Text theme='intro'><strong>Or, Call Us:</strong></Text>
            </Paragraph>
            <Paragraph>
              <Text theme='intro'>
                <a className='phone-number' href='tel:+1-855-763-3427'>855.763.3427</a>
              </Text>
            </Paragraph>
          </Section>
        </Main>
      </Page>
      <Footer hiddenPilotCta={true} />
    </Layout>
  )
}
