import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledP,
} from './styles'

const Paragraph = ({
  theme, ...props
}) => <StyledP theme={theme} {...props} />

Paragraph.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'center',
    'short',
  ]),
}

Paragraph.defaultProps = {
  theme: 'default',
}

export default Paragraph
