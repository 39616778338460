import React, {
  useState,
} from 'react'
import axios from 'axios'
import Recaptcha from 'react-recaptcha'
import {
  useForm,
} from 'react-hook-form'

import {
  navigate,
} from 'gatsby'
import {
  FormWrap, FormRow, FormRowSplit, FormButtonRow,
} from './styles'

import {
  FormRecapchaRow,
} from '../../../StyledComponents'

const FormsContact = () => {
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm()

  const [
    isVerified,
    setIsVerified,
  ] = React.useState(false)

  const verify = () => {
    if (!isVerified) {
      alert('Please verify that you are human.')
      return false
    }
    return true
  }

  const onSubmit = e => {
    if (!verify()) return false

    // static 'key' prevents random submissions to API endpoint. It is checked on the other end.
    const data = {
      firstname: document.getElementById('firstname').value,
      lastname: document.getElementById('lastname').value,
      email: document.getElementById('email').value,
      organization: document.getElementById('organization').value,
      topic: document.getElementById('topic').value,
      comments: document.getElementById('comments').value,
      key: '4131cb16-db72-4510-a9ea-5acd24e8236c',
    }

    axios.post(`${process.env.SERVERLESS_API_URL}/contact-form`, data)
      .then(() => {
        navigate('/thank-you')
      })
      .catch(error => {
        alert(error)
      })
  }

  const recaptchaLoaded = () => {}

  const verifyCallback = response => {
    try {
      if (response) {
        setIsVerified(true)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <FormWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRowSplit>
          <label htmlFor='firstname'>
            First Name
            <input
              id='firstname'
              name='firstname'
              type='text'
              {...register('firstname', { required: true })}
            />
            {errors.firstname && <span className='error'>First name is required</span>}
          </label>
          <label htmlFor='lastname'>
            Last Name
            <input
              id='lastname'
              name='lastname'
              type='text'
              {...register('lastName', { required: true })}
            />
            {errors.lastname && <span className='error'>Last name is required</span>}
          </label>
        </FormRowSplit>
        <FormRow>
          <label htmlFor='email'>
            Email
            <input
              id='email'
              name='email'
              type='email'
              {...register('email', { required: true })}
            />
            {errors.email && <span className='error'>E-Mail is required</span>}
          </label>
        </FormRow>
        <FormRow>
          <label htmlFor='organization'>
            Medical Organization Name
            <input
              id='organization'
              name='organization'
              type='text'
              {...register('organization', { required: true })}
            />
            {errors.organization && <span className='error'>Organization is required</span>}
          </label>
        </FormRow>
        <FormRow>
          <label htmlFor='topic'>
            Topic
            <select
              id='topic'
              name='topic'
              defaultValue=''
              {...register('topic', { required: true })}
            >
              <option value='' disabled hidden>
                Choose One
              </option>
              <option value='General inquiry.'>General inquiry.</option>
              <option value='I‘m interested in ChartMedic for my organization.'>
                I‘m interested in ChartMedic for my organization.
              </option>
              <option value='I have a sales related question.'>
                I have a sales related question.
              </option>
              <option value='I need technical support.'>
                I need technical support.
              </option>
              <option value='I would like to provide product feedback.'>
                I would like to provide product feedback.
              </option>
            </select>
            {errors.topic && <span className='error'>Topic is required</span>}
          </label>
        </FormRow>
        <FormRow>
          <label htmlFor='comments'>
            Comments
            <textarea rows='1' id='comments' name='comments' />
          </label>
        </FormRow>
        <FormRecapchaRow style={{
          width: 'fit-content',
        }}
        >
          <Recaptcha
            sitekey='6LcN_PoUAAAAAPMe-NEg4uKdlrrPJZ7qfXr8H-8s'
            render='explicit'
            onloadCallback={recaptchaLoaded}
            verifyCallback={verifyCallback}
          />
        </FormRecapchaRow>
        <FormButtonRow>
          <input type='submit' value='Send Message' />
        </FormButtonRow>
        <input
          type='hidden'
          name='_subject'
          value='Contact Inquiry for ChartMedic'
        />
      </form>
    </FormWrap>
  )
}
export default FormsContact
