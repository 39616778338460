import styled from 'styled-components'
import FONTS from '../../../../constants/fonts'
import COLORS from '../../../../constants/colors'
import MEDIA from '../../../../constants/media'
import SelectArrow from '../../../../assets/images/select-arrow.png'

export const FormWrap = styled.div`
  background: ${COLORS.WHITE};
  margin: auto;
  padding: 25px 30px 30px 30px;
  width: 100%;
  max-width: 72rem;
  label {
    font-size: ${FONTS.FONTLABEL};
  }
  input,
  textarea {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid ${COLORS.BORDERS};
    &:focus {
      outline: none;
    }
  }
  select {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid ${COLORS.BORDERS};
    background-color: ${COLORS.WHITE};
    background-image: url(${SelectArrow});
    background-size: 16px 15px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 44px;
    &:focus {
      outline: none;
      background-color: ${COLORS.WHITE};
      border-bottom: 1px solid ${COLORS.BORDERS};
    }
    &:invalid {
      color: ${COLORS.GREYMEDIUM};
    }
  }
  button,
  input[type='submit'] {
    background: ${COLORS.BLUE};
    border-radius: 4px;
    color: ${COLORS.WHITE};
    cursor: pointer;
    display: inline-block;
    font-size: ${FONTS.FONTBUTTON};
    margin: 5px;
    padding: 20px 35px;
    text-decoration: none;
    display: inline-block;
    width: auto;
  }
  label .error {
    color: red;
  }
`
export const FormTitle = styled.div`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.FONTFORMTITLE};
`
export const FormRow = styled.div`
  ${MEDIA.xxsmall`
    input {
      margin-bottom: 4rem;
    }
    select {
      margin-bottom: 4rem;
    }
  `};
`
export const FormRowSplit = styled(FormRow)`

  ${MEDIA.medium`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label {
      width: calc(50% - .5rem);
      padding-right: 1rem;
    }
  `}
  ${MEDIA.xxsmall`
    input {
      margin-bottom: 4rem;
    }
  `}
`
export const FormRowFull = styled(FormRow)`
  label {
    margin-bottom: 2rem;
  }
  ${MEDIA.medium`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label {
      width: calc(100% - .5rem);
      padding-right: 1rem;
    }
  `}
`
export const FormButtonRow = styled.div`
  margin-top: 25px;
  ${MEDIA.medium`
    display: flex;
    justify-content: center;
  `}
`
