import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledText,
} from './styles'

const Text = ({
  theme, ...props
}) => <StyledText theme={theme} {...props} />

Text.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'small',
    'medium',
    'large',
    'intro',
    'columns',
  ]),
}

Text.defaultProps = {
  theme: 'default',
}

export default Text
